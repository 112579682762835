import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Helmet } from "react-helmet";
import BotonDeSubir from "../common/BotonDeSubir";
import Footer from "../common/Footer";
import Header from "../common/Header";
import ModalCookies from "../common/ModalCookies";
import SectionTitle from "../common/SectionTitle";
import "./css/Preguntas.css";

const Preguntas = () => {
  const [visible, setVisible] = useState("");
  const [consulta, setConsulta] = useState(0);
  const [preguntaAbierta, setPreguntaAbierta] = useState(false);
  const [idAnterior, setIdAnterior] = useState(0);

  const mostrar = (id) => {
    setVisible("mostrar");
    setConsulta(id);

    if (idAnterior === id) {
      setPreguntaAbierta(!preguntaAbierta);
      return;
    }

    setPreguntaAbierta(true);
    setIdAnterior(id);
  };

  return (
    <>
      <Header />
      <Helmet>
        <title>Preguntas - Cursos de Ofimatica</title>
        <meta name="description" content={"Preguntas - Cursos de Ofimatica"} />
        <meta
          name="keyword"
          content="cursos de ofimatica"
        />
      </Helmet>
      <BotonDeSubir />
      <SectionTitle title="Preguntas" />
      <main>
        <div className="questions-section">
          <h2 className="section-title-questions">Preguntas frecuentes</h2>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(1);
              }}
            >
              <h3>¿Qué es el sitio de CursosDeOfimatica.com? </h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 1 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 1 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                El sitio web{" "}
                <span className="subrayado">www.cursosdeofimatica.com</span> es
                una plataforma que tiene actualmente disponible una serie de
                micro cursos sumamente importantes y actualizados para el sector
                educacional que aspira a mejorar cualquier negocio educativo.
                Son capacitaciones asincrónicas (cursos a propio ritmo, que uno
                los hace cuando tiene un tiempo disponible), que permite
                entrenarse y adquirir nuevas habilidades a propio ritmo. Al
                inscribirse se tendrá acceso a todos los cursos disponibles en
                la plataforma para aprender, conocer y aplicar las técnicas más
                actuales de la ofimatica. Más adelante podremos sumar
                otros servicios adicionales que también guardarán relación al
                sector de interés.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(2);
              }}
            >
              <h3>¿Quién es responsable del servicio brindado?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 2 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 2 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                El sitio web de cursosdeofimatica.com y los servicios brindados
                en él, son desarrollados íntegramente por el GRUPO IDT, LLC™,
                sociedad constituida en Miami, Estados Unidos de América, con
                Registro de Contribuyente 30-1276141 y su subsidiaria en
                Paraguay, IDT S.A., con Registro Único de Contribuyente
                80084507-2. IDT, es un Grupo Corporativo con más de 24 años de
                trayectoria en el ámbito educativo basado en las nuevas
                tecnologías; Nos apasionamos por la calidad y la innovación
                constante.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(3);
              }}
            >
              <h3>¿Cómo inscribirse?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 3 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 3 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                Se abona una membresía anual, a través de la sección de
                “Inscribirse” o los botones de “Inscríbete”, una vez allí,
                deberás completar con tus datos personales y de pago, aceptar
                nuestros “Términos y Condiciones Generales”, y hacer clic para
                finalizar la compra de la suscripción anual con la cual ya
                podrás hacer uso inmediato de nuestros servicios. En el correo
                electrónico declarado recibirás los accesos para ingresar a la
                plataforma de cursosdeofimatica.com y empezar así a disfrutar
                de un gran número de capacitaciones que te aseguramos
                transformarán tu negocio educativo. Y cada tanto iremos subiendo
                más y nuevos cursos de suma utilidad para el sector
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(4);
              }}
            >
              <h3>¿Cuáles son las formas de pago?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 4 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 4 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                Una vez hecho el registro en la sección de “Inscribirse” se te
                redirigirá a la pasarela de pago, para abonar con tarjeta de
                crédito o PayPal. El pago es único y tiene vigencia durante 1
                año.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(5);
              }}
            >
              <h3>¿Cuáles son los cursos disponibles?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 5 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 5 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                Tenemos más de 24 cursos online actualmente. Pero periódicamente
                iremos subiendo y actualizando nuevas currículas de cursos en el
                ámbito de la ofimatica por lo que siempre tendrás nuevos
                cursos para seguir estudiando en línea mientras estés suscripto.
                Para ver la lista completa, podrás hacerlo ingresando a este
                enlace:{" "}
                <a href="/" className="enlace-cursos">
                  https://www.cursosdeofimatica.com/
                </a>
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(6);
              }}
            >
              <h3>¿Los cursos tienen fecha de inicio?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 6 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 6 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                No te preocupes, los cursos iniciarás en el momento que desees,
                y seguir a tu propio ritmo cada lección, cada curso. Podrás
                acceder las 24 horas del día, sin depender de nadie para
                comenzar o culminar cualquier curso.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(7);
              }}
            >
              <h3>¿Cuánto tiempo debo dedicarle al día?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 7 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 7 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                Depende de qué tan rápido quieras aprender y el tiempo que
                dispongas para hacerlo. Por lo general, los estudiantes pasan
                entre 30 y 60 minutos al día, algunos lo hacen en horario de
                almuerzo, al terminar la jornada laboral o de la facultad, los
                fines de semana con mayor inversión en tiempo. Siempre lo
                importante es prestar atención durante el proceso de
                capacitación y práctica, enfocarse en ello en cada sesión que
                realices es muy clave para el aprovechamiento de nuestras
                ofertas educativas. Podrás igualmente volver a repetir las veces
                que creas importante cada lección aprendida.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(8);
              }}
            >
              <h3>¿Cuánto dura cada curso?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 8 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 8 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                Cada curso dentro de la plataforma cursosdeofimatica.com tiene
                los detalles de la duración en horas. Estas varían de acuerdo a
                cada tema tratado.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(9);
              }}
            >
              <h3>
                ¿Existe algún requisito para iniciar los cursos en
                cursosdeofimatica.com?
              </h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 9 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 9 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                Una vez abonada la suscripción anual, tendrás acceso inmediato a
                todos los cursos y solamente contarán tus ganas y el tiempo de
                dedicación para aprovechar el gran caudal y potencial de
                información de utilidad que tenemos preparado para vos, lo cual
                te ayudará no solamente a mejorar tu perfil profesional si no
                además a colaborar con cualquier institución educativa que desee
                proyectar su negocio educacional a escalar.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(10);
              }}
            >
              <h3>¿Cómo se guarda el progreso del curso?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 10 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 10 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                Para iniciar el curso, solo deberás hacer clic en la primera
                lección, en el botón “Comenzar”, al abrir cada sección de cada
                lección tendrás a la vista el check de que ya finalizaste esa
                sección, al terminar cada sección de la misma lección, solo
                deberás hacer clic en “Continuar”. La plataforma registra cada
                proceso de aprendizaje y almacena el progreso durante el curso,
                la próxima vez que retomes el curso podrás hacerlo continuando
                en la sección donde estabas anteriormente. Al ingresar a la
                sección de “Cursos” debajo de cada curso, también podrás ver tu
                progreso actual.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(11);
              }}
            >
              <h3>¿Se otorgan certificados?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 11 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 11 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                Cuando se finaliza un curso, se otorga automáticamente un
                certificado en formato digital. Observación: Los certificados
                siempre estarán a nombre de la persona que se registró en la
                plataforma. Podrás descargar el certificado las veces que
                quieras, durante la vigencia de tu licencia en el Campus.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(12);
              }}
            >
              <h3>¿Debo estar en contacto con el docente?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 12 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 12 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                No será necesario, en cada clase tendrás la explicación de la
                actividad, los materiales, videos tutoriales y/o resúmenes que
                serán de gran utilidad para aprender al máximo a tu propio
                ritmo.
              </p>
            </div>
          </button>

          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => {
                mostrar(13);
              }}
            >
              <h3>¿Quiénes son los docentes?</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className={
                  consulta === 13 && preguntaAbierta === true
                    ? "icon-preguntas"
                    : ""
                }
              />
            </div>
            <div
              className={
                visible === "mostrar" &&
                consulta === 13 &&
                preguntaAbierta === true
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                La calidad de nuestros docentes y de los materiales educativos
                que producimos son nuestro mayor orgullo y compromiso. Los
                docentes son profesionales con años de experiencia en la
                enseñanza de educación a distancia y expertos en cada área en
                que imparten sus conocimientos. Cada docente se encuentra en
                constante actualización en metodología de aprendizaje eficiente
                y en la elección de los mejores contenidos para que cada
                estudiante aproveche al máximo los contenidos curriculares.
              </p>
            </div>
          </button>
        </div>
      </main>
      <Footer />
      <ModalCookies />
    </>
  );
};

export default Preguntas;
