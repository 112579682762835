import React  from 'react';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Header from "../common/Header";
import Footer from "../common/Footer";
import SectionTitle from "../common/SectionTitle";

import 'react-tabs/style/react-tabs.css';
import './css/Nosotros.css'
import './css/Politicas.css'

import { Helmet } from "react-helmet";


const Nosotros = (props) => {

    return (
        <>
            <Header/>
            <Helmet>
          <title>
            {"Politicas de privacidad y reembolso - Cursos de Ofimática"}
          </title>
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={"Politicas de privacidad y reembolso - Cursos de Ofimática"}
          />
          <meta
            property="og:description"
            content={"Politicas de privacidad y reembolso"}
          />
          <meta
            property="og:url"
            content="https://www.cursosdeofimatica.com/politicas/"
          />
          <meta property="og:site_name" content="Cursos de Ofimatica" />
          <meta
            property="article:published_time"
            content={""}
          />
          <meta
            property="article:modified_time"
            content={""}
          />
          <meta
            property="og:image"
            content="https://www.cursosdeofimatica.com/wp-content/uploads/2021/05/proteccion-online-3-767x587.png"
          />
          <meta property="og:image:width" content="768" />
          <meta property="og:image:height" content="588" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Escrito por" />
          <meta
            name="twitter:data1"
            content={'GRUPO IDT S.A.'}
          />
          <meta name="twitter:label2" content="Tiempo de lectura" />
          <meta name="twitter:data2" content="30 minutos" />
          <meta
            name="description"
            content={"Politicas de privacidad y reembolso - Cursos de ofimatica"}
          />
          <meta
            name="keyword"
            content="curso de office, curso, word, excel, powerpoint, scratch, certificacion"
          />
        </Helmet>
            <SectionTitle title={'Políticas y privacidad de uso'}/>
            <section className={'container'}>
                <div className={'post-content'}>
                                                    
                <h2>Términos y Condiciones Generales</h2>
                <p class="has-text-align-center"><strong>1. Introducción</strong></p>
<p>Bienvenido a Cursos de Ofimática punto com (cursosdeofimatica.com), proporcionado por Grupo IDT, LLC™ (en adelante “GRUPO IDT”, “IDT” o “IDT S.A.”). El presente documento establece los términos y condiciones generales del uso (“Términos y Condiciones Generales”) que serán aplicables cuando usted visite y/o utilice la Plataforma Digital y/o el Sitio Web https://www.cursosdeofimatica.com/ (en adelante, de manera conjunta o separada “al sitio”, “el sitio”, “del sitio”, “del sitio web”, “el sitio web” y/o “plataforma”), cuyas titularidades y contenidos son de total autoría del GRUPO IDT, LLC™, sociedad constituida en Miami, Estados Unidos de América, con Registro de Contribuyente 30-1276141.</p>
<p>A continuación, se muestra la información legal aplicable al GRUPO IDT y su subsidiaria en Paraguay: IDT S.A.</p>
<p>Es necesario también leer las Políticas de Privacidad, en donde detallamos nuestras prácticas de manejo de información las cuales forman parte integral y no limitativas, de nuestras políticas de servicios. Nos preocupamos por mantener seguros a nuestros usuarios y estamos en constante trabajo para recopilar la información de manera confidencial. El acceso y/o utilización del sitio atribuye al internauta la condición de usuario del sitio web (el “Usuario” o los “Usuarios”), lo cual implica la plena aceptación de todas las disposiciones incluidas en el presente documento.</p>

<p class="has-text-align-center"><strong>2. Definiciones</strong></p>
<p>Sin perjuicio de los términos definidos en diversas secciones del presente documento, los términos aquí utilizados y que no sean definidos de forma diversa, tendrán el significado que a continuación se les atribuye, pudiendo ser utilizados en masculino, femenino o neutro, mayúscula o minúscula, así como en singular o en plural, indistintamente y según el contexto:</p>
<p>“Cookie” es el archivo de datos que se almacena en el dispositivo del Usuario cuando éste tiene acceso al Sitio.</p>
<p>“Cuenta del Usuario” es la forma de identificación creada (que consta de un ID de acceso y contraseña) que se le da a los Usuarios para que puedan acceder a los Servicios que ofrece el GRUPO IDT a través de la Plataforma. (En adelante, la “Cuenta” o “Cuenta del Usuario”).</p>
<p>“Curso” es aquel espacio virtual curricular el cual se encargará de impartir conocimientos respecto de una determinada materia y/o tema en particular a un número de Usuarios mediante clases pre grabadas dictadas a través de la Plataforma de IDT.</p>
<p>“Leyes Vigentes” significan todas las leyes, códigos, normas jurídicas, ordenanzas, reglamentos, tratados, reglas, normas, permisos, resoluciones, órdenes, interpretaciones, decisiones, interdictos, así como las normas, resoluciones y sentencias que actualmente o en el futuro estén vigentes en Paraguay y resulten aplicables a los Usuarios.</p>
<p>“Membresías” es el término con el que se define a los tipos de suscripción por un tiempo limitado a nuestros Servicios, los cuales otorgan opciones de accesos a los Cursos y Servicios que ofrece IDT a través de la Plataforma, a fin de que el Usuario pueda elegir la opción más conveniente dependiendo de sus preferencias, tarifas, y Cursos.</p>
<p>“Plataforma” se refiere al sitio web, plataforma virtual, y otros medios digitales como aplicaciones para dispositivos smartphones con tecnología IOS o Android, tablets, SmartTv, entre otros, cuyas titularidades corresponden a IDT, y que dentro de los cuales los Usuarios se registran para obtener el Servicio.</p>
<p>“Producto” es el término que define el acceso al Curso o a un paquete de Cursos, dentro del catálogo de Cursos ofrecido por IDT, independientemente a la suscripción de una Membresía. En caso el Usuario elija acceder a uno de los Productos, este podrá acceder al Curso y visualización de clases virtuales grabadas y demás recursos que estén disponibles. </p>
<p>“Servicio” es el servicio brindado por IDT el cual consiste en poner a disposición de sus Usuarios, Cursos sobre diversas temáticas o ámbitos de conocimiento, disciplina académica, ciencia o arte, los cuales son dictados por Profesores especializados en la materia. </p>
<p>“Usuario” es aquella persona natural que se registra en la Plataforma con la finalidad de contratar el Servicio y/o los Servicios expuestos por IDT. </p>
<p>“Tarifa” es el precio que el Usuario deberá pagar para acceder a alguna de las Membresías que ofrece IDT. La tarifa podrá ser pagada a través de los medios de pago que pone a su disposición IDT en la Plataforma.</p>

<p class="has-text-align-center"><strong>3. Descripción del Sitio</strong></p>
<p>La Plataforma es un sitio web educativo para personas que quieren aprender habilidades competitivas. A través de la Plataforma, los Usuarios se inscriben a los cursos ofertados, a través de los cuales pueden tener acceso a contenidos audiovisuales y recursos digitales que se desarrollarán a propio ritmo. </p>

<p class="has-text-align-center"><strong>4. Aceptación de las condiciones</strong></p>
<p>IDT ha puesto a su disposición la Plataforma, por lo que, al acceder a ella, usted debe de leer, comprender y aceptar estos Términos y Condiciones Generales (las “Condiciones”) Asimismo, queda sujeto a estas Condiciones que, junto con nuestras Políticas de Privacidad, rigen nuestra relación con usted dentro de la Plataforma. Solo podrá registrarse y ser Usuario de la Plataforma, después de haber aceptado las Condiciones, en caso de no aceptarlas, se le solicita que no haga uso, ni acceda, ni manipule la información de los Servicios ofrecidos por la Plataforma, ya que usted estaría haciendo un uso inadecuado de ésta.</p>

<p class="has-text-align-center"><strong>5. Disponibilidad de los Contenidos</strong></p>
<p>Los contenidos mostrados a través de la Plataforma podrán incluir todo tipo de archivos, que podrán ser propios o de terceros, tales como textos, imágenes, sonidos y videos, enlaces (links) a otros sitios o aplicaciones ajenos a IDT, y cualquier otro contenido que IDT considere pertinente agregar.</p>
<p>IDT no garantiza que los contenidos que se ofrecen en la Plataforma estén disponibles siempre, por lo que IDT se reserva el derecho de suspender o adicionar cualquier funcionalidad en la Plataforma.</p>
<p>IDT se reserva el derecho de agregar, cambiar, terminar, remover o suspender cualquier material incorporado a la Plataforma, incluyendo características, precios y especificaciones de los Productos descritos o reseñados en el Sitio, en forma temporal o permanente, en cualquier momento, sin previo aviso y sin responsabilidad alguna.</p>

<p class="has-text-align-center"><strong>6. Acceso y utilización del Sitio Web</strong></p>
<p>Los Usuarios podrán disfrutar de todas las funcionalidades y Servicios que ofrece la Plataforma, desde que se registran en la Cuenta y opten por alguna de las Membresías de pago, salvo IDT haya lanzado algún plan gratuito al momento del registro y se especifiquen limitaciones.</p>
<p>El acceso y el uso de la Plataforma sólo podrá ser realizado por aquellas personas que conforme a la legislación aplicable tengan capacidad de ejercicio pleno para celebrar actos jurídicos. En caso que, algún menor de edad, quiera acceder a los Servicios que ofrezca la Plataforma, los que ejerzan la patria potestad o tutores de los menores de edad o de aquellas personas reputadas por las Leyes Vigentes como sujetos de derecho con incapacidad absoluta serán responsables por los actos realizados por estos últimos.</p>
<p>Por lo tanto, en términos legales, el Usuario debe tener al menos 18 años de edad para aceptar estos Términos y Condiciones Generales, quedando entendido, sin embargo, que, si fuera menor de 18 años, sus padres o tutor legal puede aceptarlos en su nombre y ellos serán absolutamente responsables de las derivaciones legales que implique el uso de la Plataforma.</p>
<p>Como Usuario deberá hacer uso de su Membresía solo para motivos educacionales y nunca con la intención de copiar materiales o técnicas para su beneficio o llevar a otras plataformas competidoras similares o ajenas a las del Grupo IDT.</p>
<p>Como Usuario cuando adquiere una Membresía, ya sean pagas o gratuitas, IDT le da un acceso a sus Productos a través de la Plataforma. Como Usuario usted tiene licencia para acceder a dichos Productos y/o a la Plataforma, pero no le estamos vendiendo ningún contenido. Esta licencia no le otorga ningún derecho a revender el Producto, total o parcialmente, de ninguna manera (incluso compartiendo información de su cuenta con un tercero o descargando ilegalmente los Productos o compartiéndola en sitios de descargas de torrents).</p>
<p>En términos legales más completos, IDT le otorga (como Usuario) una licencia limitada, no exclusiva e intransferible para acceder y ver y utilizar sus Productos y el contenido asociado por el que ha pagado todas las tarifas requeridas, exclusivamente para fines personales educativos, no comerciales, de sus Cursos y Productos. Por lo tanto, no puede reproducir, redistribuir, transmitir, asignar, vender, retransmitir, alquilar, compartir, prestar, modificar, adaptar, editar o crear trabajos derivados, subarrendar, transferir o utilizar ningún Producto, total o parcialmente, a menos que le demos permiso explícito para hacerlo en un acuerdo escrito firmado por un representante autorizado por IDT.</p>

<p class="has-text-align-center"><strong>7. Registro</strong></p>
<p>Los Usuarios deberán registrarse en la Plataforma para crearse una Cuenta, debiendo completar el formulario de registro con toda su información personal tales como nombres completos, un nombre de usuario, colocar un correo electrónico válido, entre otros. Asimismo, deberá elegir una contraseña.</p>
<p>Solo se podrá registrar una cuenta por Usuario. El Usuario deberá de proporcionar información certera, completa y actualizada. No hacerlo representa una violación de los presentes Términos y Condiciones Generales y podría significar la cancelación de la cuenta, si en caso IDT lo considere conveniente.</p>
<p>El nombre de Usuario elegido al momento del registro no deberá atentar contra las buenas costumbres, vulnerar los derechos de terceros, provocar equívocos, confundir a otros usuarios ni atentar contra la reputación o imagen de IDT, reservándose el derecho de modificar cualquier nombre de usuario que considere inapropiado sin previo aviso.</p>
<p>Usted declara y garantiza que toda la información (incluida la Información de Registro y las respuestas a los sondeos y encuestas) que proporciona a IDT es correcta, completa y vigente, y se compromete a actualizar dicha información cuando sea necesaria. También admite que cualquier información adicional proporcionada por usted a IDT no violará ninguna ley o reglamento, ni infringirá los derechos de terceros.</p>

<p class="has-text-align-center"><strong>8. Responsabilidad por contraseñas</strong></p>
<p>Es responsabilidad del Usuario cuidar sus datos de ingreso y de cualquier actividad dentro de su cuenta, por lo que deberá mantenerla bajo absoluta reserva y confidencialidad, quedando prohibida la acción de compartirla con terceros. Toda acción realizada a través de la Cuenta del Usuario se presume realizada por este y no se admite prueba en contrario.</p>
<p>IDT no se hace responsable de los daños y perjuicios que puedan suceder por actos de seguridad ni de los daños relacionados con la divulgación del nombre del Usuario o de su contraseña. Si existe algún tipo de violación de seguridad o identifica que alguien ha accedido a su cuenta, el Usuario tiene la obligación de notificarlo instantáneamente a IDT.</p>
<p>En caso de olvido de la contraseña o de cualquier otra circunstancia que suponga un riesgo de acceso y/o de utilización por parte de terceros no autorizados, el Usuario lo comunicará inmediatamente a IDT, a fin de proceder inmediatamente con el bloqueo y/o sustitución de la misma. En todo caso, todas las operaciones o transacciones realizadas con anterioridad a dicha comunicación se entenderán efectuadas por el Usuario, quien, de ser el caso, será responsable y pagará los gastos y los daños y perjuicios que se deriven de cualquier acceso o uso no autorizado a la Plataforma.</p>

<p class="has-text-align-center"><strong>9. Uso correcto de la Plataforma</strong></p>
<p>La Plataforma podrá ser utilizada únicamente con fines lícitos y que no transgredan las buenas costumbres y/o el presente documento.</p>
<p>El Usuario se compromete a utilizar la Plataforma con fines lícitos y que no transgredan el ordenamiento jurídico, el orden público y las buenas costumbres. En ese sentido, a título meramente enunciativo, pero no limitativo, el Usuario se abstendrá, entre otros, de:</p>
<ul>
<li>Abusar, acosar, amenazar, suplantar o intimidar a cualquier persona.</li>
<li>Publicar o transmitir cualquier tipo de contenido difamatorio, obsceno, pornográfico, abusivo, ofensivo, profano o que infrinja derecho de autor o derechos en general de cualquier persona.</li>
<li>Intentar acceder o realizar búsquedas en motores de búsquedas o softwares (spiders, robots, crawlers, herramientas de minería de datos o similares) distintos de aquellos provistos por IDT o aquellos motores de búsquedas genéricos provistos por terceros y de acceso público en Internet.</li>
<li>Generar o enviar correos no deseados a cualquier persona o URL.</li>
<li>Publicar contenidos de los cuales no posea los derechos de autor.</li>
<li>Vender o transferir su Cuenta de Usuario.</li>
<li>Utilizar la Plataforma para fines comerciales o para el beneficio de terceros de manera que infrinja lo establecido en el presente documento.</li>
<li>Manipular cualquier paquete TCP/IP, encabezado (header) o cualquier parte de la información de encabezado en cualquier correo electrónico, newsgroup, newsletter o utilizar de cualquier manera la Plataforma para enviar información falsa, alterada o que no permita identificar la fuente original desde donde se envió.</li>
<li>Intentar descifrar, descompilar o aplicar ingeniería en reversa a cualquier programa de software proveedor del Sitio Web, entre otros.</li>
</ul>
<p>Asimismo, IDT ha establecido los siguientes lineamientos generales para el correcto uso de los Servicios y Membresías, por lo que el Usuario deberá acatarlas y alentar a otros Usuarios a hacer lo mismo:</p>
<ul>
<li>El Usuario podrá hacer uso de los Servicios de IDT, bajo conformidad con las Leyes Vigentes.</li>
<li>El Usuario podrá acceder de manera ilimitada a los recursos que ofrece la Plataforma y descargar los contenidos dentro del plazo de vigencia de la Membresía elegida, solamente en el caso que así se le especifique y para los usos especificados estrictamente en el presente documento.</li>
<li>Si no se especifica, el Usuario no podrá descargar, grabar, archivar, reproducir, distribuir, modificar, mostrar, ejecutar, publicar, otorgar licencias, ni crear obras derivadas, basadas en el contenido de titularidad de IDT u obtenida a través de su Plataforma.</li>
<li>El Usuario dependiendo de la Membresía que elija tendrá permitido guardar el acceso a su Cuenta en más de un dispositivo (smartphones, tablets, SmartTv). Sin embargo, no podrá acceder a su Cuenta de manera simultánea en más de un dispositivo.</li>
<li>El Usuario, eventualmente, podrá compartir contenidos de manera pública en la Plataforma, por lo que, no deberá realizar acciones, comentarios o compartir archivos que demuestren acoso, referencias sexuales, abuso, bullying, racismo, spam, u otra acción que atente contra las normas y las buenas costumbres, o que afecten a la comunidad de IDT.</li>
<li>IDT se reserva el derecho de retirar, terminar o suspender, sin previo aviso y en cualquier momento la Cuenta a aquellos Usuarios que infrinjan los presentes Términos y Condiciones Generales, por lo que, por favor, le pedimos que no use el sitio para ningún fin ilícito y/o negativo.</li>
</ul>
<p class="has-text-align-center"><strong>10. Productos y Membresías que ofrece la Plataforma</strong></p>
<p>IDT pone a disposición de sus Usuarios Productos y Membresías.</p>
<p>A los Productos que IDT exponga a través de la Plataforma, se les denominará “Productos”, los que incluyen Cursos y/o paquetes de Cursos, seleccionados conforme a intereses de la temática del sitio. El Usuario podrá acceder a los Productos realizando un pago único anual a través de la Plataforma, y tendrá acceso a sus contenidos de manera ilimitada durante la vigencia de la anualidad de la Membresía. </p>
<p>IDT se reserva el derecho de retirar, modificar y/o agregar Membresías y sus Productos en la Plataforma, según lo considere conveniente.</p>

<p class="has-text-align-center"><strong>11. Cursos y especializaciones</strong></p>
<p>IDT mantiene sus Cursos y Especializaciones a la vanguardia y deseamos que nuestros Usuarios tengan una experiencia completa a través de la Plataforma. </p>
<p>Los Cursos estarán integrados por un determinado número de clases virtuales grabadas, así como recursos digitales varios, cuando así lo determine necesario.</p>
<p>Por otro lado, la evaluación del desenvolvimiento y aprendizaje de los Usuarios serán condicionados para su aprobación dentro de la Plataforma, ya sea en reproducciones de las clases, test, elaboración de proyectos, entre otros.</p>

<p class="has-text-align-center"><strong>12. Tarifas</strong></p>
<p>IDT se reserva el derecho de cambiar sus tarifas de cargo por su Membresía o Productos ofrecidos en la Plataforma (o de comenzar a cobrar por cualquier Producto promocional gratuito, previo aviso), tomando en cuenta que las tarifas pagadas anteriores a dichas modificaciones no resultarán afectadas.</p>
<p>Ocasionalmente ejecutamos promociones y ofertas para nuestros Productos y/o Membresías, y algunos contenidos pueden estar disponibles a precios con descuento durante un determinado periodo de tiempo. El precio que se aplica al Producto o Membresía será el precio vigente en el momento en que se concrete la compra de los mismos.</p>
<p>Todos los precios relacionados con estos Productos o Membresías están sujetos a cambios por parte de IDT en cualquier momento, sin previo aviso y sin responsabilidad alguna hacia usted. IDT no ofrece una protección de precios o rembolsos en caso de una caída de precios o una oferta promocional.</p>

<p class="has-text-align-center"><strong>13. Políticas de reembolso</strong></p>
<p>Si el Producto de la Plataforma que ha adquirido no es lo que esperaba, puede solicitar, en un plazo máximo de 30 días desde la adquisición, que IDT le realice un reembolso. </p>
<p>En caso de haber realizado la compra de un Curso, Producto o Membresía, y éste no haya sido de su agrado, puede solicitar su reembolso, siempre y cuando se cumplan las siguientes condiciones:</p>
<ul>
<li>La solicitud de reembolso debe haberse realizado en un plazo no mayor a 30 días desde la adquisición. </li>
<li>Solo será válido el reembolso si no se ha visualizado más del 33% del contenido.</li>
</ul>
<p>A nuestra entera discreción, si consideramos que está abusando de nuestra política de reembolsos, por ejemplo, si ya ha solicitado el reembolso de otros Productos o Membresías en este sitio u otros servicios y sitios que ofrece el IDT, nos reservamos el derecho a restringir sus solicitudes de reembolso futuras, prohibir su cuenta o restringir su utilización futura de los Productos o Membresías que ofrecemos. Si prohibimos su cuenta o desactivamos su acceso al contenido debido a su infracción de estas condiciones o nuestras directrices sobre confianza y seguridad, no reunirá los requisitos para recibir un reembolso nuevamente.</p>

<p class="has-text-align-center"><strong>14. Formas de Pago</strong></p>
<p>IDT se reserva el derecho de contratar a terceros que actúen como facilitadores para gestionar los pagos de las Tarifas que se realicen a través de la Plataforma. Asimismo, IDT procederá primero a verificar que el pago de la Tarifa haya sido debidamente efectuado para proceder con la activación de la Membresía.</p>
<p>“Pago en línea”, para lo cual el Usuario podrá efectuar el pago de la Tarifa con tarjetas de crédito y/o débito a través de la pasarela de pagos de la Plataforma.</p>
<p>Para algunas Formas de pago, el emisor puede cobrarle ciertos cargos, como cargos de transacción extranjera u otros cargos relacionados con el procesamiento de su Forma de pago. Asimismo, el Usuario se obliga a pagar todos los impuestos aplicables por su legislación, en caso los hubiere.</p>
<p>Recuerde que, al realizar su compra, la tarjeta que utilice se quedará guardada como método de pago predeterminado. Sin embargo, puede eliminar esta información cuando desee, ingresando en la configuración de su cuenta.</p>

<p class="has-text-align-center"><strong>15. Sobre impuestos propios de cada país por compras en el Sitio:</strong></p>
<p>Los precios base que se muestran en la Plataforma son en dólares americanos, el monto que ve en su moneda local es un aproximado que puede variar de acuerdo a su banco y el tipo de cambio del día. IDT es una empresa constituida en Estados Unidos de América y en Paraguay, por lo que es posible que se apliquen impuestos propios según país por compras en plataformas extranjeras y que deberán ser asumidos por los Usuarios finales.</p>
<p>Las compras en el Servicio de la Plataforma, pueden incluir el impuesto sobre ventas o el impuesto al valor agregado (cuando proceda) y estas no depende directamente de IDT.</p>

<p class="has-text-align-center"><strong>16. Cancelación y renovación</strong></p>
<p>Cancelación. - El Usuario podrá cancelar su Membresía cuando lo considere conveniente. Si se cancela, perderá acceso a todos las Cursos a los cuales se haya inscrito (a menos que estas sean de acceso gratuito). Si decide pagar nuevamente recuperará el acceso a los Cursos inscritos.</p>

<p class="has-text-align-center"><strong>17. Promociones, ofertas, lanzamientos comerciales</strong></p>
<p>En cualquier momento, IDT podrá lanzar ofertas promocionales, Cursos gratuitos, inclusive becas para alumnos nuevos, entre otros, por lo que, éstos se regirán bajo las condiciones y restricciones adicionales que se establezcan al momento de su lanzamiento, los cuales serán publicados en el sitio.</p>
<p>De la misma manera, a fin de que las Membresías de IDT se acomoden a las necesidades de sus Usuarios, por lo que, IDT podrá lanzar nuevas Membresías al mercado, las cuales se regirán por los Términos y Condiciones Particulares que se establezcan en dicho momento.</p>

<p class="has-text-align-center"><strong>18. Propiedad intelectual</strong></p>
<p>El software asociado a la Plataforma, así como todos los contenidos disponibles en el mismo, son propiedad exclusiva de IDT, y/o de ser el caso, de sus proveedores o de sus servidores externos; por tanto, están protegidos por las leyes y los tratados internacionales en materia de derechos de autor, marcas, patentes, modelos y diseños industriales aplicables en la República del Paraguay, sin importar que se encuentren o no registrados.</p>
<p>Se encuentra terminantemente prohibido cualquier tipo de copia, distribución, transmisión, retransmisión, publicación, impresión, difusión y/o explotación comercial del material y/o contenido puesto a disposición del público a través de la Plataforma, sin el previo consentimiento expreso y por escrito de IDT o, en su caso, del titular de los derechos de propiedad intelectual correspondientes. El incumplimiento de lo aquí mencionado sujetará al infractor a todos los reclamos civiles y será materia para la presentación de la querella, la denuncia o la reclamación procedente ante las autoridades competentes, dentro de la cual se proporcionará la información necesaria para la investigación y persecución del ilícito del que se trate.</p>

<p class="has-text-align-center"><strong>19. Derechos de Autor</strong></p>
<p>Todos los derechos de autor involucrados en la Plataforma, como por ejemplo su contenido y su diseño visual, son de titularidad de IDT y/o de sus proveedores, por lo que se encuentra estrictamente prohibido su empleo, modificación, reproducción, distribución, transmisión o comercialización de los derechos involucrados sin el permiso previo, expreso y por escrito de los titulares de dichos derechos, salvo los casos de derechos de cita, empleo para finalidades educativas y los demás usos honrados reconocidos.</p>
<p>Todos nuestros Cursos y/o Productos están protegidos por las leyes aplicables de derechos de autor y los tratados internacionales de derechos de autor, así como por otras leyes y tratados de propiedad intelectual, nacionales e internacionales. Los mismos se licencian, no se vende. El Usuario no puede realizar copias no autorizadas o utilizar ninguna parte de los mismos, salvo como se especifica en este documento y de conformidad con la legislación aplicable. Todos los títulos y derechos de autor de cada Curso, de cada Producto, de la Plataforma de Servicio (incluyendo, entre otros, imágenes, fotografías, animaciones, vídeo, audio, código, artículos, texto y "applets", incorporados al Servicio), son propiedad de IDT o sus licenciadores. Usted como Usuario acepta cumplir con todas las leyes aplicables sobre protección de derechos de autor en relación con el uso de estos recursos. Además de lo estipulado en estos Términos y Condiciones Generales, IDT podrá adoptar medidas adecuadas para proteger los derechos de autor basado en sus contenidos de propia autoría.</p>

<p class="has-text-align-center"><strong>20. Privacidad</strong></p>
<p>De conformidad con la Ley de Protección de Datos Personales, IDT implementará todas las medidas posibles para mantener la confidencialidad y la seguridad de la información y datos de los Usuarios, pero no responderá por los daños, pérdidas o perjuicios que pudieran derivarse de la violación de esas medidas por parte de terceros que utilicen las redes públicas o internet, alterando los sistemas de seguridad para obtener acceso a la información de los Usuarios.</p>
<p>Sin perjuicio de lo anterior, el uso de la Plataforma se encuentra condicionado a la lectura y aceptación libre por parte del Usuario de la Política de Privacidad, la cual se encuentra a su disposición en la Plataforma, por lo que, en caso de registrarse como Usuario en IDT, el Usuario manifiesta su conformidad con las Políticas de Privacidad.</p>
<p>IDT cuenta con una red de servicios y sitios asociados, y realiza alianzas con otras instituciones, organizaciones y corporaciones, razón por la cual en virtud de dichas alianzas y con las autorizaciones pertinentes, se reserva el derecho de volver a contactar a las personas que hayan participado como usuarios finales (beneficiarios) en alguna de estas alianzas o en su propia red de servicios y sitios asociados.</p>
<p>En caso de que le hayamos contactado de esta manera y no desee recibir nuestra información, puede solicitarnos que le retiremos de nuestra base de datos a través de nuestro formulario de contactos.</p>

<p class="has-text-align-center"><strong>21. Cookies</strong></p>
<p>El Usuario conviene en recibir las Cookies que les transmitan los servidores de IDT. Las Cookies pueden contener información tal como la identificación proporcionada por el Usuario o información para rastrear las páginas que el Usuario ha visitado. Por ello, es importante que lea y acepte la Política de Cookies que se encuentra a disposición en la Plataforma, a fin de poder registrarse como Usuario y acceder a los Servicios.</p>

<p class="has-text-align-center"><strong>22. Exclusión de garantías</strong></p>
<p>Respecto de errores o virus en la Plataforma, IDT no es responsable de los posibles daños o perjuicios en la Plataforma, que puedan derivar de interferencias, omisiones, interrupciones, virus informáticos, averías o desconexiones en el funcionamiento operativo del sistema electrónico, motivado por causas ajenas a IDT de retrasos o bloqueos en el uso del sistema causados por deficiencias o sobrecargas en Internet o en otros sistemas electrónicos, así como también de daños que puedan ser causados por terceras personas mediante intromisiones ilegítimas fuera del control de IDT.</p>
<p>Respecto de los Servicios, IDT, sus empleados o directivos no son responsables de los daños o perjuicios indirectos, accidentales o por eventos de caso fortuito, incluyendo daños por lucro cesante, pérdida de uso y pérdida de datos derivados de o en relación con los Cursos, los Proyectos y/o los presentes términos.</p>

<p class="has-text-align-center"><strong>23. Disponibilidad y suspensión del Servicio</strong></p>
<p>En el supuesto de que algunas de las Membresías que ofrece IDT, dejen de estar disponible para el público en general, se respetarán las condiciones de los Usuarios que ya adquirieron dicha Membresía en su momento hasta el término de plazo de la misma.</p>
<p>En caso de incumplimiento de cualquiera de las disposiciones contenidas en los presentes Términos y Condiciones Generales, IDT podrá suspender la Cuenta, suspender el Servicio y/o retirarle o denegarle el acceso a la Plataforma o al Sitio Web, en cualquier momento y sin necesidad de notificación previa de ningún tipo, así como, de ser el caso, presentar las denuncias ante las autoridades competentes. Se considerará infracción a los presentes Términos y Condiciones Generales, la comisión o participación en actos que constituyan conductas abusivas, inapropiadas o ilícitas, actividades fraudulentas o ilegales y en general el comercio de cualquier objeto ilícito o producto de un delito.</p>
<p>Ni IDT, ni sus proveedores y cesionarios garantizan que el sitio se mantendrá libre de errores o que el acceso al mismo sea siempre continuo y sin interrupciones. Entiende el Usurario que descargará o conseguirá contenido o servicios a través del Sitio bajo su propio riesgo.</p>
<p>Por lo cual no se garantiza que el funcionamiento del servicio sea ininterrumpido ni que esté exento de errores. Ninguna información o asesoramiento oral o escrito proporcionado por cualquier persona, constituirá ningún tipo de garantía en relación con la Plataforma o sus afiliadas.</p>
<p>IDT no garantiza que el servicio sea compatible con cualquier computadora personal, reproductor de cd o dvd, grabador de cd o dvd, o dispositivos portátiles registrados. Usted es el único responsable de asegurarse de que sus sistemas funcionen correctamente con el servicio y contar con las versiones adecuadas de los programas informáticos (software) necesarios recomendados para el buen uso y aprovechamiento de los Servicios y Cursos que ofrece la Plataforma.</p>

<p class="has-text-align-center"><strong>24. Cambios en las Condiciones y Cesión de Posición contractual</strong></p>
<p>IDT se reserva el derecho de actualizar, modificar o reemplazar cualquier parte de estas Condiciones, sin necesidad de previo aviso. Todos los Términos y Condiciones Generales modificados entrarán en vigor al momento de su publicación para todos los efectos legales a que haya lugar.</p>
<p>Es la responsabilidad del Usuario revisarlo de manera periódica para poder identificar cambios. Su navegación en la Plataforma supondrá la aceptación de cualquier modificación realizada en las Condiciones. Además, IDT podría incluir nuevos Servicios o funciones en el futuro. De ser el caso, tales Servicios se verán sujetos a las Condiciones especificadas en este documento. En caso de no estar de acuerdo con las modificaciones que se pudiesen dar, el Usuario podrá cancelar su suscripción y/o presentar sus reclamos, dudas y/o a través del formulario de contactos del sitio.</p>
<p>Asimismo, IDT podrá ceder o transferir los derechos y obligaciones como su posición contractual derivados del presente documento y/o de la Plataforma, en cualquier momento. Por lo que, el Usuario autoriza expresamente la cesión del presente documento, así como la Política de Privacidad en favor de cualquier persona que (i) quede obligada por el presente; y/o (ii) que sea el nuevo responsable de los derechos y/o obligaciones que surjan del presente documento y/o de la Plataforma. Luego de producida la cesión, IDT no tendrá ninguna responsabilidad con respecto de cualquier hecho que ocurra, a partir de la fecha de la cesión.</p>
<p>Al utilizar la Plataforma, el Usuario acuerda que IDT o una de sus filiales podrán, en el momento de una fusión, adquisición o venta de la misma, o de todos o de sustancialmente todos los activos del IDT, ceder sus derechos y obligaciones en relación con el servicio brindado por la Plataforma a un propietario u operador aplicable posterior. En caso de dicha fusión, adquisición o venta, el uso continuado de la Plataforma significa su acuerdo a obligarse por los Términos y Condiciones Generales y la Política de Privacidad del propietario u operador posterior del Servicio.</p>

<p class="has-text-align-center"><strong>25. Indemnización</strong></p>
<p>El Usuario mantendrá indemne a IDT, sus filiales, empresas controladas y/o controlantes, directivos, administradores, representantes y empleados, proveedores y asociados, respecto de cualquier reclamo o demanda de otros Usuarios o terceros por las actividades que el Usuario pueda realizar en la Plataforma, y/o por cualquier incumplimiento a las Condiciones y demás Políticas que se entienden incorporadas al presente o por la violación de cualquier ley o derechos de terceros.</p>

<p class="has-text-align-center"><strong>26. Prohibición a acciones de grupo</strong></p>
<p>Tanto usted como nosotros aceptamos que solo podemos presentar reclamaciones contra el otro únicamente de forma individual y no en acciones grupales. Esto significa lo siguiente: (a) ninguno de nosotros puede presentar una reclamación como parte demandante o miembro de clase en una acción de grupo, acción consolidada o acción representativa; (b) un árbitro no puede combinar las reclamaciones de varias personas en un solo caso (o presidir cualquier acción consolidada, de clase o representativa); y (c) la decisión o el laudo de un árbitro en el caso de una persona solo puede tener un impacto en dicho usuario, no en otros usuarios, y no se puede utilizar para tomar una decisión respecto a los conflictos de otros usuarios. </p>
<p>Si un tribunal decide que esta cláusula (“Prohibición a acciones de grupo”) no es aplicable o válida, esta sección se considerará anulada e invalidada, pero el resto de las Condiciones seguirán en vigor.</p>

<p class="has-text-align-center"><strong>27. Resolución de conflictos</strong></p>
<p>Si existe un conflicto, nuestro Equipo de soporte estará encantado de ayudarle a resolverlo. Si esta medida no funciona, sus opciones se limitan a acudir a los juzgados de primera instancia o presentar una reclamación mediante arbitraje vinculante en Paraguay. No puede presentar la reclamación en otro tribunal ni participar en una acción de grupo no individual contra nosotros.</p>
<p>La mayoría de los conflictos pueden resolverse, por lo tanto, antes de comenzar un proceso jurídico oficial, intente ponerse en contacto con nuestro Equipo de soporte.</p>

<p class="has-text-align-center"><strong>28. Jurisdicción y legislación aplicable</strong></p>
<p>Todas las controversias que deriven de la interpretación o el cumplimiento de los presentes Términos y Condiciones Generales o que guarden relación con éstos, incluyendo cualquier reclamo que pudiera una de las Partes tener en contra de la otra derivada de, o relacionado con el uso de la Plataforma y/o de los Servicios, se regirá por las leyes paraguayas, renunciando en este acto las Partes a cualquier otra legislación o jurisdicción que, por motivo de sus domicilios presentes o futuros, pudiere corresponderles y se someten a los Juzgados y Tribunales del Distrito Judicial de Paraguay.</p>
<p>Para sugerencias y/o comentarios sobre los Términos y Condiciones Generales de nuestra Plataforma pueden enviarnos una comunicación a través del formulario de contactos del sitio.</p>
<p>Declaro que luego de haber leído los Términos y Condiciones Generales, acepto todo lo anteriormente mencionado. En señal de conformidad, procedo a llenar el formulario de compra.</p>
                </div>
            </section>
            <Footer/>
        </>

    );
};

export default Nosotros;


