import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiCache from "../utlis/ApiCache";
import mejorarUrl from "../utlis/mejorarUrl";

const AtrasAdelante = ({ publicacion, tipo }) => {
  const endPointPublicacion =
    tipo === "blog"
      ? `${process.env.REACT_APP_API_WP}posts?per_page=100&_embed&categories=`
      : `${process.env.REACT_APP_API_LMS}courses?per_page=100&_embed&category=`;
  //const [categorias, setCategorias] = useState([]);
  const categorias = [];
  const [publicaciones, setPublicaciones] = useState([]);
  let isMounted = true;

  //setCategorias(tipo === "blog" ? publicacion?.categories : publicacion?.categories_object[0]?.id);
  if (tipo === "blog") {
    categorias.push(publicacion?.categories);
  } else {
    /* categorias.push(publicacion?.categories_object[0]?.id); */
    categorias.push(146);
    categorias.push(147);
  }

  const url =
    tipo === "blog"
      ? `${endPointPublicacion}${
          Array.isArray(categorias) ? categorias.join(",") : categorias
        }`
      : endPointPublicacion;
  const indicePublicacion = {
    anterior: null,
    anterior_publicacion: {
      id: null,
      slug: null,
    },
    actual: null,
    siguiente: null,
    siguiente_publicacion: {
      id: null,
      slug: null,
    },
  };

  useEffect(() => {
    ApiCache(`${tipo}-id-${publicacion?.id}`, url)
      .then((r) => {
        if (isMounted) {
          if (tipo === "blog") {
            setPublicaciones(r);
          } else {
            setPublicaciones(r.courses);
          }
        }
        console.log(publicaciones);
      })
      .catch((err) => console.log(err));
    return () => {
      isMounted = false;
    };
  }, [url, publicacion?.id]);

  let ordenarCursos = {
    categ_146: [],
    categ_147: [],
  };

  if (tipo !== "blog") {
    ordenarCursos.categ_146 = publicaciones.filter((curso) => {
      return curso.categories_object.find((el) => el.id === 146);
    });
    ordenarCursos.categ_147 = publicaciones.filter((curso) => {
      return curso.categories_object.find((el) => el.id === 147);
    });
  }
  /* console.log("ordenarCursos: ",ordenarCursos); */

  const nuevasPublicacaciones =
    tipo === "blog"
      ? publicaciones
      : ordenarCursos.categ_147.concat(ordenarCursos.categ_146);

  if (nuevasPublicacaciones.length) {
    indicePublicacion.actual = nuevasPublicacaciones.findIndex((elemento) => {
      return elemento.id === publicacion?.id;
    });

    if (indicePublicacion.actual > 0) {
      indicePublicacion.anterior = indicePublicacion.actual - 1;
    } else {
      indicePublicacion.anterior = nuevasPublicacaciones.length - 1;
    }

    if (indicePublicacion.actual === nuevasPublicacaciones.length - 1) {
      indicePublicacion.siguiente = 0;
    } else {
      indicePublicacion.siguiente = indicePublicacion.actual + 1;
    }

    indicePublicacion.anterior_publicacion.id =
      nuevasPublicacaciones[indicePublicacion.anterior].id;
    indicePublicacion.siguiente_publicacion.id =
      nuevasPublicacaciones[indicePublicacion.siguiente].id;

    if (tipo === "blog") {
      indicePublicacion.anterior_publicacion.slug =
        nuevasPublicacaciones[indicePublicacion.anterior].slug;
      indicePublicacion.siguiente_publicacion.slug =
        nuevasPublicacaciones[indicePublicacion.siguiente].slug;
    } else {
      indicePublicacion.anterior_publicacion.slug = mejorarUrl(
        nuevasPublicacaciones[indicePublicacion.anterior].title
      );
      indicePublicacion.siguiente_publicacion.slug = mejorarUrl(
        nuevasPublicacaciones[indicePublicacion.siguiente].title
      );
    }
  }

  /* console.log("atrasAdelante",publicacion);
    console.log("categorias", categorias);
    console.log("url",url);
    console.log("indicePublicacion", indicePublicacion); */
  if (tipo === "blog") {
    return (
      <>
        <div className="container-pagination">
          <ul className="pagination">
            <li className="">
              <Link
                to={`/${tipo}/${indicePublicacion.anterior_publicacion.slug}`}
                className={"invisible-link ss"}
                key={`publicacion-anterior`}
                style={{
                  background: "var(--color-principal)",
                  color: "white",
                  border: "2px solid var(--color-principal",
                }}
              >
                Anterior
              </Link>
            </li>
            <li className="">
              <Link
                to={`/${tipo}/${indicePublicacion.siguiente_publicacion.slug}`}
                className={"invisible-link"}
                key={`publicacion-siguiente}`}
                style={{
                  background: "var(--color-principal)",
                  color: "white",
                  border: "2px solid var(--color-principal",
                }}
              >
                Siguiente
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="container-pagination">
          <ul className="pagination">
            <li className="">
              <Link
                to={`/${tipo}/${indicePublicacion.anterior_publicacion.id}/${indicePublicacion.anterior_publicacion.slug}`}
                className={"invisible-link"}
                key={`publicacion-anterior`}
                style={{
                  background: "var(--color-principal)",
                  color: "white",
                  border: "2px solid var(--color-principal",
                }}
              >
                Anterior
              </Link>
            </li>
            <li>
              <Link
                to={"/inscribirse"}
                style={{
                  background: "var(--color-principal)",
                  color: "white",
                  border: "2px solid var(--color-principal)",
                  padding: "5px 35px",
                  /* width: '181px', 
                    display: 'block', */
                  margin: "0 auto",
                  textTransform: "uppercase",
                }}
                key={`publicacion-inscribirse`}
              >
                Inscribirse
              </Link>
            </li>
            <li className="">
              <Link
                to={`/${tipo}/${indicePublicacion.siguiente_publicacion.id}/${indicePublicacion.siguiente_publicacion.slug}`}
                className={"invisible-link"}
                key={`publicacion-siguiente}`}
                style={{
                  background: "var(--color-principal)",
                  color: "white",
                  border: "2px solid var(--color-principal",
                }}
              >
                Siguiente
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
};

export default AtrasAdelante;
