import React from "react";
import { Link } from "react-router-dom";
import mejorarImg from "../utlis/mejorarImg";
import mejorarUrl from "../utlis/mejorarUrl";
import "./TarjetaCurso.css";

const TarjetaCurso = (props) => {
  const { curso } = props;

  const urlWebP = "https://www.cursosdeofimatica.com/image.php?url=";

  const mejorarImg = (imgx) => {
    if (imgx === null) {
      /* return { CursosDeOfimatica }; */
    } else {
      //console.log(imgx);
      let imgx2 = imgx.split("-");
      //console.log(imgx2);
      const ultimo = imgx2[imgx2.length - 1];
      const vectorExtension = ultimo.split(".");
      imgx2.pop();
      //console.log(imgx2.join("-") + "." + vectorExtension[1]);
      return `${imgx2.join("-")}.${vectorExtension[1]}`;
    }
  };

  //console.log(curso);

  return (
    <>
      <Link
        to={`/cursos/${curso.id}/${mejorarUrl(curso.title)}`}
        className={"invisible-link"}
        key={`movie-${curso.id}`}
      >
        <div className={"tarjeta-curso"}>
          <img
            src={`${urlWebP}${mejorarImg(`${curso.images.full}`)}`}
            alt={`${curso.title}`}
          />
          <h2 className={"title"}>{curso.title}</h2>
          <span>{curso.categories[0] ?? ""}</span>
        </div>
      </Link>
    </>
  );
};

export default TarjetaCurso;
